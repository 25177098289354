<template>
  <v-container fluid>
    <v-layout column>
      <v-card>
        <v-card-text>
          <v-text-field
            v-model="company.name"
            label="Name"
          />
          <v-text-field
            v-model="company.phoneNumber"
            label="Phone Number"
          />
          <v-text-field
            v-model="company.messageNumber"
            label="Message Number"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            :loading="loading"
            @click.native="submitCompany()"
          >
            Save Company
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import { getCompany, createCompany, updateCompany } from '@/shared/apis/companyApi';

export default {
  pageTitle: 'Company',
  components: {},
  data() {
    return {
      loading: false,
      company: {
        name: '',
        phoneNumber: '',
        messageNumber: '',
      },
    };
  },
  computed: {
    companyId() {
      return parseInt(this.$route.params.id, 10);
    },
  },
  async created() {
    if (this.$route.params.id > 0) {
      this.company = await getCompany(this.$route.params.id);
    }
  },
  methods: {
    async submitCompany() {
      const data = {
        name: this.company.name,
        phoneNumber: this.company.phoneNumber,
        messageNumber: this.company.messageNumber,
      };
      let result;
      if (this.companyId === 0) {
        result = await createCompany(data);
        this.$store.commit('showMessage', { content: 'Company Created.', color: 'success' });
        this.$router.push(`/company/${result.id}`);
      } else {
        result = await updateCompany(this.companyId, data);
        this.$store.commit('showMessage', { content: 'Company Updated.', color: 'success' });
        this.customer = await getCompany(this.companyId);
      }
    },
  },
};
</script>
